import Vue from "vue";
import App from "./App.vue";
import ElementUi from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import plugins from "./plugins"; // plugins
import vueEsign from "vue-esign";
import scroll from "vue-seamless-scroll";
import { addDateRange } from "@/utils/ruoyi";
import * as VueGoogleMaps from "vue2-google-maps";
// 分页组件
import Pagination from "@/components/Pagination";
Vue.prototype.addDateRange = addDateRange;
Vue.config.productionTip = false;
Vue.component("Pagination", Pagination);
Vue.use(ElementUi);
Vue.use(plugins);
Vue.use(vueEsign);
Vue.use(scroll);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBJkDGTtSajKCrXz9UTDv5I4dnsLyWazyY",
    libraries: "places",
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
