<template>
  <div id="app">
    <div class="div_menu_bg">
      <menu-navigation-bar />
    </div>
    <router-view />
    <div class="bottom_infor_fixed">
      <bottom-infor />
    </div>
  </div>
</template>
<script>
import menuNavigationBar from "../src/components/menuNavigationBar/index.vue";
import bottomInfor from "../src/components/bottomInfor/index.vue";
export default {
  components: {
    menuNavigationBar,
    bottomInfor
  }
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
  /* background:#292e3c; */
}

#app {
  margin: 0;
  padding: 0;
}

.div_menu_bg {
  position: absolute;
  width: 100%;
}

.bottom_infor_fixed {
  width: 100%;
  position: absolute;
}
</style>
