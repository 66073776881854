import request from "@/utils/request";

// 登录方法
export function login(username, password) {
  const data = {
    username,
    password,
  };
  return request({
    url: "/front/web/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 学员注册方法
export function registeruSer(data) {
  return request({
    url: "/front/web/driver/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}
// 教练注册方法
export function registeruSerCoach(data) {
  return request({
    url: "/front/web/coach/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/front/web/logout",
    method: "post",
  });
}
