<template>
  <div class="box_bottom_contact" v-if="isLogin">
    <div class="contact_information_box" v-if="showInforPhone">
      <span class="contact_us">联系我们 Contact</span>
      <div class="display_infor">
        <ul class="infor_left">
          <li>地址：12-65 Bentley Avenue</li>
          <li>邮编：517000</li>
          <li>邮箱：Windir@AxureUX.com</li>
        </ul>
        <ul class="infor_left" style="margin-left: -20%">
          <li>手机：18088886666 / 18088886666</li>
          <li>传真：0755-88886666</li>
          <li>电话：0755-88886666</li>
        </ul>
        <ul class="infor_left">
          <li>营业时间：</li>
          <li>周一 到 周五 9:00AM-6:00PM</li>
          <li>周六 10:00AM-5:00PM</li>
        </ul>
      </div>
    </div>
    <div class="bottom_footer">Copyright ©, All Rights Reserved.</div>
  </div>
</template>
<script>
export default {
  name: "TopMenu",
  data() {
    return {
      showInforPhone: false,
      isLogin: true
    };
  },
  watch: {
    "$route.name": function(newVal, oldVal) {
      if (
        newVal == "studentCenter" ||
        newVal == "registerCoach" ||
        newVal == "registerDriver" ||
        newVal == "coachDetail" ||
        newVal == "chooseCoach" ||
        newVal == "registrationSuccess" ||
        newVal == "ratingDetails" ||
        newVal == "paySuccess" ||
        newVal == "goodsDetail" ||
        newVal == "coachRatingRoad" ||
        newVal == "coachRatingRoadView" ||
        newVal == "viewDetails" ||
        newVal == "coachCenter" ||
        newVal == "lessonManagement" ||
        newVal == "drivingtestManagement" ||
        newVal == "driverRoadScoreDetail" ||
        newVal == "coachOfficialRate" ||
        newVal == "driverOfficialRate" ||
        newVal == "officialRateDetail" ||
        newVal == "driverChangePassword" ||
        newVal == "driverRatingRoad" ||
        newVal == "accountActivity" ||
        newVal == "changePassword" ||
        newVal == "scoreDetails" ||
        newVal == "scoreDetails2" ||
        newVal == "problemsDetail"
      ) {
        this.showInforPhone = false;
      } else {
        this.showInforPhone = true;
      }
      if (newVal == "login" || !newVal) {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
    },
    deep: true
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.box_bottom_contact {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  // position: relative;

  .contact_information_box {
    width: 100%;
    height: 264px;
    background: #ffffff;
    padding: 60px 8%;
    box-sizing: border-box;

    .contact_us {
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: bold;
      font-size: 24px;
      color: #2a2e43;
    }

    .display_infor {
      display: flex;
      justify-content: space-between;

      .infor_left {
        list-style-type: none;
        padding: 0;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #78849e;
        line-height: 36px;
      }
    }
  }

  .bottom_footer {
    width: 100%;
    height: 80px;
    background: #1c1c1e;
    text-align: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 80px;
    box-sizing: border-box;
    // position: absolute;
    // bottom: -80px;
  }
}
</style>
